.slide-primary-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding: 0 20px;
}
.slide-primary-item {
    position: relative;
    margin-bottom: 20px;
}
.slide-primary-item img {
    height: 100%;
    width: 100%;
}
.slide-delete {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5px;
    cursor: pointer;
}